import * as React from "react"
import Layout from "../../components/layout"

import Seo from "../../components/seo"

import productbg1Img from "../../images/productbg1.png"

import Banner from "../../components/Banner/BannerMain"
import CoreModules from "../../components/CoreModules"
import AboutImgOne from "../../images/about1.png"
import AboutImgTwo from "../../images/about2.png"
import SectionTitle from "../../components/SectionTitle"
import DemoBookingSection from "../../components/DemoBookingSection"
import RealTimeImg from "../../images/real-time.png"
import CustomizedImg from "../../images/customized.png"
import TimelineImg from "../../images/timeline.png"
import CommentImg from "../../images/comment.png"

import AutomatedImg from "../../images/automated-process.png"
import WorkersImg from "../../images/workers.png"

const modules = [
  {
    id: 1,
    title: "Structured Deal Sourcing",
    img: AutomatedImg,
    paragraph:
      "Create a standardized deal inflow structure that is specific to your organization",
  },
  {
    id: 2,
    title: "Real-time Reporting",
    img: RealTimeImg,
    paragraph:
      "Always know the status of your pipeline",
  },
  {
    id: 3,
    title: "Multi-Sourcing Channels",
    img: WorkersImg,
    paragraph:
      "Allow multiple people to provide potential leads ",
  },
  {
    id: 4,
    title: "Centralized Data",
    img: CustomizedImg,
    paragraph:
      "Keep all of the information on a potential opportunity in one place for the entire team to see",
  },
  {
    id: 5,
    title: "Deal Timeline",
    img: TimelineImg,
    paragraph:
      "Have an audit trail of how deals developed within your organization",
  },
  {
    id: 6,
    title: "Idea Exchange",
    img: CommentImg,
    paragraph:
      "Allow your team to leave and see comments on a deal with the right context",
  },
]

function Rover() {
  return (
    <Layout>
      <Seo title="Rover" />
      <section
        id="home"
        className="header overflow-hidden bg-emerald-50 relative  items-center flex w-full"
      >
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
          <Banner
            title="Seek. Find. Explore "
            titleTag="Rover"
            subTitle=""
            subTag=""
            img={productbg1Img}
          />
        </div>
      </section>
      <section className="relative body-font w-full pt-10 pb-3 md:pb-20">
        <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
          <SectionTitle
            titleHead="Core features"
            title="Features which together make a"
            spanTag="complete system"
            customClass="text-center md:w-full xl:w-full"
          />
          <CoreModules modules={modules} />
        </div>
      </section>

      <section className="relative body-font w-full pt-10 pb-3 md:pb-10 bg-themeGray">
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
          <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-3 md:mb-12 pt-20 md:pt-40">
            <div
              className="w-full lg:w-1/2 lg:pr-16 relative order-1"
              data-aos="fade-up"
            >
              <img
                className="bg-white rounded shadow-lg rounded-lg absolute left-[0px] -top-[100px] animate-none md:animate-pulse 1s linear infinite"
                src={AboutImgTwo}
              />
              <img
                className="bg-white rounded shadow-lg rounded-lg "
                src={AboutImgOne}
              />
            </div>
            <div className="w-full lg:w-1/2 mt-10 md:mt-0 mb-2 lg:mb-0 order-2">
              <h4
                className="text-xl md:text-3xl font-medium mb-5"
                data-aos="fade-up"
              >
                Empower your organization with the {" "}
                <span className="text-emerald-800 mr-1 inline-block">
                Rover pipeline management tool
                </span>
              </h4>
              <p className="leading-relaxed text-sm" data-aos="fade-up">
                With Rover Opportunity module, the process of seeking, analysing
                and converting opportunity to a Project is much easier,
                organised, and seamless.
              </p>
              <ul className="list-none mt-6" data-aos="fade-up">
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Machine Learning Algorithm to assit in analyzing a
                        opportunity
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Seek and Research multiple opportunities
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Single pane view of your opportunities
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Connect with developers for potential opportunity
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">Vendor Engagement</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <DemoBookingSection />
    </Layout>
  )
}

export default Rover
