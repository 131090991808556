import React from "react"
import ModuleItem from "./ModuleItem"

const CoreModules = ({
  modules,
}: {
  modules: { id: number; title: string; img: string; paragraph: string }[]
}) => {
  return (
    <>
      <div className="flex flex-wrap -mx-4">
        {modules.map(module => (
          <ModuleItem
            key={module.id}
            title={module.title}
            img={module.img}
            paragraph={module.paragraph}
          />
        ))}
      </div>
    </>
  )
}

export default CoreModules
