import React from "react"

const ModuleItem = (
    {img,title,paragraph}:{img:string, title:string, paragraph:string}
    ) => {
  return (
    <>
        <div className="p-4 md:w-1/3" data-aos="fade-up">
            <div className="flex flex-col h-full  border border-themeBorder overflow-hidden py-6 px-7  rounded-lg  ease-linear transition-all duration-200   hover:shadow-lg cursor-pointer">
                <div className="mb-1">
                    <div className="w-12 h-12 inline-flex">
                        <img className="" src={img} />
                    </div>
                    <h2 className="text-textColor text-lg title-font font-semibold mb-0">
                        {title}
                    </h2>
                </div>
                <div className="flex-grow">
                    <p className="leading-relaxed text-sm">
                        {paragraph}
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ModuleItem
